<template>
  <div class="flex overflow-hidden height-full parts-list-table">
    <PartsCategorySearch @change="changeQueryData" ref="partsCategorySearch" class="m-lr-5 m-tb-5" />
    <div class="flex1 overflow-hidden  p-a-5">
      <HtTable
        ref="partsListTable"
        v-bind="$attrs"
        v-on="$listeners"
        :loading="loading"
        :data="partsListData"
        :columns="partsListTableColumns"
        :selected-rows.sync="selectedRows"
        :location-row-index="locationRowIndex"
        :table-name="partsListTable.tableName"
        :show-table-setting="true"
        :is-show-default-select-column="true"
        :is-default-first-row="false"
        :autofocus="true"
        :pagination="true"
        :current-page="page"
        :total="totalSize"
        :key-scope="keyScope"
        :row-class-name="rowClassName"
        selection-type="multiple"
        empty-text="暂无商品"
        :select-table-row="selectTableRowFn"
        @row-click="rowClick"
        @table-setting="editColumnsVisible = true"
        @pageChange="pageChange"
        @hotkeysUp="hotkeysUp"
        @rowOperation="rowOperation"
        @current-row-change="currentRowChange"
        @set-location-row-index="setLocationRowIndex"
        @sort-change="sortChange"
      >
        <div slot="pagination" class="flex flex-row-center">
          <span class="m-l-10"> 已选中{{ selectedRows.length }}个配件 | 批量操作 </span>
          <el-button class="m-l-10" type="primary" size="mini" :disabled="!selectedRows.length" @click="addBatchData">
            加入购物车(Alt+F1)
          </el-button>
        </div>
      </HtTable>
    </div>
    <EditColumns
      :table-name="partsListTable.tableName"
      :base-columns="partsListTableBaseColumns"
      :visible.sync="editColumnsVisible"
      @columns-change="columnsChange"
    />
    <!-- 展示图片框 -->
    <div class="demo-image__preview" style="display:none;">
      <el-image
        ref="imgpreview"
        v-if="imageSrc"
        style="width: 0px; height: 0px"
        :src="imageSrc"
        :preview-src-list="imagesList"
      >
      </el-image>
    </div>
    <ProductsDetailsBox ref="productsDetailsBox" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ProductsDetailsBox from "@/components/ProductsDetailsBox";
import PartsCategorySearch from "./PartsCategorySearch";
import { PARTS_LIST_TABLE } from "@/constants/parts-list-table";
import { getGoodsList, getGoodsDetail } from "@/api/goods";
import { addShoppingCart } from "@/api/shoppingCart";

export default {
  name: "PartsListTable",
  components: {
    PartsCategorySearch,
    ProductsDetailsBox
  },
  props: {
    queryData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      imageSrc: "",
      imagesList: [],
      loading: false,
      keyScope: "partsListTable",
      sort: "",
      page: 1,
      totalSize: 0,
      locationRowIndex: 0,
      currentRow: {},
      selectedRows: [],
      editColumnsVisible: false,
      partsListTable: PARTS_LIST_TABLE,
      partsListTableColumns: [],
      partsListData: []
    };
  },
  computed: {
    ...mapState(["table"]),
    ...mapGetters("companyInfo", ["noLoginLook"]),
    company() {
      return this.$store.state.companyInfo.company;
    },
    partsListTableBaseColumns() {
      const columns = JSON.parse(JSON.stringify(this.partsListTable.columns));
      //库存展示维度是否勾选仓库
      if (!this.company.setting.stockShowModeWarehouse) {
        const warehouseIndex = columns.findIndex(item => item.prop === "warehouse");
        columns.splice(warehouseIndex, 1);
      }
      //库存展示维度是否勾选生产批号
      if (!this.company.setting.stockShowModeProductBatch) {
        const productBatchIndex = columns.findIndex(item => item.prop === "productBatch");
        columns.splice(productBatchIndex, 1);
      }
      return columns;
    }
  },
  watch: {
    queryData: {
      handler() {
        setTimeout(() => {
          this.pageChange(1);
        }, 200);
      },
      deep: true
    },
    selectedRows() {
      this.$emit("setSelectedRows", this.selectedRows);
    }
  },
  mounted() {
    this.$bus.$on("refreshPartsList", () => {
      this.pageChange(1);
    });
  },
  beforeDestroy() {
    this.$bus.$off("refreshPartsList");
  },
  methods: {
    rowClick(row) {
      this.$emit("setSelectedRows", [row]);
    },
    rowClassName({ row }) {
      let className = "";
      if (row.qty.includes("无货") || Number(row.qty) < 1) {
        className += "row-font-danger";
      }
      return className;
    },
    // 是否是多规格
    isMoreModelFn(row) {
      return !!row.isSpecification;
    },
    // 是否可选择 多规格：不可选 单规格：可选 isSpecification（是否是多规格）
    selectTableRowFn(row) {
      return !row.isSpecification;
    },
    getGoodsList() {
      if (this.loading) return;
      const data = this.dealWithQueryData(this.queryData);
      this.loading = true;
      this.selectedRows = [];
      getGoodsList(data)
        .then(res => {
          this.loading = false;
          let data = res.rows || [];
          data = data.map(item => {
            if (Number(item.minBuyQty) > 1 && Number(item.uQty)) {
              item.minQty = window.tool.getLCM(Number(item.minBuyQty), Number(item.uQty));
              item.orderQuantity = window.tool.getLCM(Number(item.minBuyQty), Number(item.uQty));
            } else {
              item.minQty = 1;
              item.orderQuantity = 1;
            }
            return item;
          });
          this.partsListData = data;
          this.totalSize = res.totalSize || 0;
          // 查询后焦点默认到此表格
          if (this.$refs.partsListTable) {
            this.$refs.partsListTable.$el.click();
          }
        })
        .catch(err => {
          this.loading = false;
          if (err.code !== "force-reset-password") {
            this.$message.error(err.message || "配件列表加载失败");
          }
          // 查询后焦点默认到此表格
          if (this.$refs.partsListTable) {
            this.$refs.partsListTable.$el.click();
          }
        });
    },
    dealWithQueryData(data) {
      if (data.resetCategory) {
        this.$refs.partsCategorySearch.resetCategory();
      }
      data = window.tool.deepClone(data);
      data.sort = this.sort;
      data.page = this.page;
      data.pageSize = this.table.pageSize;

      delete data.resetCategory;
      delete data.extaCode;
      return data;
    },
    columnsChange(columns) {
      this.partsListTableColumns = [];
      this.$nextTick(() => {
        this.partsListTableColumns = columns.map(item => {
          if (item.prop === "control") {
            return {
              ...item,
              render: (value, row) => {
                return (
                  <div on-click={e => this.addData(row, item, e)} class="flex flex-center">
                    <el-tooltip effect="dark" content="添加" placement="top">
                      <i class="el-icon-circle-plus text-primary font-size-18 cursor-pointer" />
                    </el-tooltip>
                  </div>
                );
              }
            };
          } else if (item.prop === "orderQuantity") {
            return {
              ...item,
              render: (value, row) => {
                return (
                  <div on-click={e => e.stopPropagation()} on-dblclick={e => e.stopPropagation()}>
                    <div class="flex">
                      {Number(row.minBuyQty) > 1 ? <div class="text-red flex1">起订量：{row.minBuyQty}</div> : null}
                      {Number(row.cartQty) > 0 ? <div class="text-primary">已添加：{row.cartQty}</div> : null}
                    </div>
                    <div class="flex flex-row-center">
                      {this.isMoreModelFn(row) ? (
                        <div on-click={e => this.addData(row, item, e)}>
                          <el-input
                            class="flex1 input-num"
                            style="max-width:110px"
                            v-model={row.orderQuantity}
                            readonly
                            disabled
                            size="mini"
                          >
                            <el-button slot="append" icon="el-icon-plus"></el-button>
                          </el-input>
                        </div>
                      ) : (
                        <el-input-number
                          class="flex1"
                          style="max-width:110px"
                          v-model={row.orderQuantity}
                          min={Number(row.minQty)}
                          step={Number(row.uQty)}
                          max={9999999}
                          size="mini"
                        />
                      )}
                      <span class="m-l-5">{row.unit}</span>
                    </div>
                    {/*<div class="text-red">今日限购，当前可订货1</div>*/}
                  </div>
                );
              }
            };
          } else if (item.prop === "name") {
            return {
              ...item,
              render: (value, row) => {
                return (
                  <div>
                    {/*<el-tag type="warning" size="mini" class="m-r-5">
                      热销
                    </el-tag>
                    <el-tag size="mini" class="m-r-5">
                      新品
                    </el-tag>
                    <el-tag type="warning" size="mini" class="m-r-5">
                      特价
                    </el-tag>*/}
                    <div class="text-primary">
                      <el-tooltip effect="dark" content={value} placement="top">
                        <span
                          class="cursor-pointer"
                          on-click={e => this.productsDetails(row, e)}
                          on-dblclick={e => e.stopPropagation()}
                        >
                          {value}
                        </span>
                      </el-tooltip>
                      <span
                        class="cursor-pointer"
                        on-click={e => this.showImg(row, e)}
                        on-dblclick={e => e.stopPropagation()}
                      >
                        {row.images && row.images.length ? <span class="tag-picture">图</span> : null}
                      </span>
                      <span
                        class="cursor-pointer"
                        on-click={e => this.productsDetails(row, e)}
                        on-dblclick={e => e.stopPropagation()}
                      >
                        {row.extaCode ? <span class="tag-exchange">互</span> : null}
                      </span>
                    </div>
                    <div>
                      <span
                        class="cursor-pointer"
                        on-click={e => this.productsDetails(row, e)}
                        on-dblclick={e => e.stopPropagation()}
                      >
                        {row.promotion ? <span class="text-red">{row.promotion}</span> : null}
                      </span>
                    </div>
                  </div>
                );
              }
            };
          } else if (item.prop === "price") {
            return {
              ...item,
              render: (value, row) => {
                return (
                  <div>
                    <div>{value}</div>
                    {Number(row.linePrice) > 0 ? <div class="line-price">￥{row.linePrice}</div> : null}
                    {Number(row.lastPrice) > 0 ? <div class="text-red">上次售价：{row.lastPrice}</div> : null}
                  </div>
                );
              }
            };
          }
          return item;
        });
      });
    },
    showImg(row, e) {
      e.stopPropagation();
      this.imageSrc = row.images[0];
      this.imagesList = row.images;
      setTimeout(() => {
        document.querySelector(".demo-image__preview .el-image__preview").click();
      }, 200);
    },
    pageChange(page) {
      this.page = page;
      this.locationRowIndex = 0;
      this.getGoodsList();
    },
    setLocationRowIndex(index) {
      this.locationRowIndex = index;
    },
    currentRowChange(currentRow) {
      this.currentRow = currentRow;
    },
    rowOperation(row) {
      this.addData(row);
    },
    sortChange(data) {
      if (data.sort) {
        this.sort = `${data.sortField}-${data.sort}`;
      } else {
        this.sort = "";
      }
      this.pageChange(1);
    },
    hotkeysUp(e) {
      switch (e.code) {
        case "Enter":
          e.preventDefault();
          e.stopPropagation();
          if (Object.keys(this.currentRow).length > 0) {
            this.addData(this.currentRow);
          }
          break;
        case "F1":
          e.preventDefault();
          e.stopPropagation();
          if (e.altKey && this.selectedRows.length) {
            this.addBatchData();
          }
          break;
      }
    },
    addData(row, columns, e) {
      if (this.noLoginLook) {
        this.$router.push({
          path: "/login"
        });
        return;
      }
      if (this.loading) return;
      e && e.stopPropagation();
      // 多规格
      if (this.isMoreModelFn(row)) {
        this.$refs.productsDetailsBox.show(row);
      } else {
        this.$refs.partsListTable.rowClick(row, columns, e, true);
        if (Number(row.orderQuantity) <= 0) {
          this.$message.warning("订货数量必须大于0");
          return;
        }
        setTimeout(() => {
          const data = [
            {
              goodsType: row.type, // 商品类型，0:普通，1:补货，2:促销组合，3:服务项目，4:套餐
              goodsId: row.id, // 商品ID
              code: row.code, // 商品编码
              name: row.name, // 商品名称
              property: row.property, // 商品性质
              warehouse: row.warehouse, // 仓库
              productBatch: row.productBatch, // 生产批号
              price: row.price, // 加购时价格
              qty: String(row.orderQuantity), // 数量
              remarks: row.remarks, // 备注
              checked: false, // 是否已选择
              areaDetailId: 0 // 商城区域详情ID，用来实时关联修理厂用
            }
          ];
          this.addShoppingCart({
            cover: false,
            data: data
          });
        }, 300);
      }
    },
    addBatchData() {
      if (this.noLoginLook) {
        this.$router.push({
          path: "/login"
        });
        return;
      }
      const data = [];
      this.selectedRows.map(item => {
        data.push({
          goodsType: item.type, // 商品类型，0:普通，1:补货，2:促销组合，3:服务项目，4:套餐
          goodsId: item.id, // 商品ID
          code: item.code, // 商品编码
          name: item.name, // 商品名称
          property: item.property, // 商品性质
          warehouse: item.warehouse, // 仓库
          productBatch: item.productBatch, // 生产批号
          price: item.price, // 加购时价格
          qty: String(item.orderQuantity), // 数量
          remarks: item.remarks, // 备注
          checked: false, // 是否已选择
          areaDetailId: 0 // 商城区域详情ID，用来实时关联修理厂用
        });
      });
      this.addShoppingCart({
        cover: false,
        data: data
      });
    },
    addShoppingCart(data) {
      this.loading = true;
      addShoppingCart(data)
        .then(() => {
          this.loading = false;
          this.$message.success("已成功加入购物车");
          this.$bus.$emit("shoppingCartRefresh");
          this.getGoodsList();
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message || "加入购物车失败");
        });
    },
    productsDetails(item, e) {
      e.stopPropagation();
      this.$router.push({
        path: "/products/details",
        query: {
          data: encodeURIComponent(JSON.stringify(item))
        }
      });
    },
    changeQueryData(data) {
      this.$emit("change", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.parts-list-table {
  .tag-picture,
  .tag-exchange {
    width: 20px;
    height: 20px;
    color: white;
    font-size: 12px;
    margin-left: 10px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .tag-picture {
    background-color: #298fd9;
  }
  .tag-exchange {
    background-color: #f28841;
  }
  ::v-deep .el-input-number--mini .el-input__inner {
    padding-left: 40px;
    padding-right: 40px;
  }
  .input-num {
    ::v-deep .el-input-group__append {
      padding: 0 7px;
    }
  }
}
</style>
